<template>
  <div>
    <el-row style="background-color: white; text-align: left; padding: 10px">
      <el-form :inline="true">
        <el-form-item label="查询时间">
          <el-date-picker
              clearable
              v-model="queryParams.time"
              class="select1"
              type="daterange"
              range-separator="To"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="queryData()">
            <el-icon>
              <Search/>
            </el-icon>
            <span>查询</span>
          </el-button>
          <el-button @click="resetHandle">
            <el-icon>
              <Refresh/>
            </el-icon>
            <span>重置</span>
          </el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <div class="bet-info">
      <el-row type="flex" justify="space-between" style="background-color: white; text-align: left; padding: 5px">
        <el-col v-for="info in betInfos" :key="info.label" :span="12">
          <div >{{ info.label }}{{ info.value }}</div>
        </el-col>
      </el-row>
    </div>
    <br/>
    <el-table :data="tableData">
      <el-table-column prop="create_time" label="获赠时间"></el-table-column>
      <el-table-column prop="type_str" label="获取来源"></el-table-column>
<!--      <el-table-column prop="type" label="赠金来源">-->
<!--        <template v-slot="{row}">-->
<!--          <template v-if="row.type===1">首充活动(关联存款订单号)</template>-->
<!--          <template v-if="row.type===2">赏金任务(赏金任务id)</template>-->
<!--          <template v-if="row.type===3">道具券(道具id)</template>-->
<!--          <template v-if="row.type===4">道具免费游戏(道具id)</template>-->
<!--          <template v-if="row.type===5">道具增益道具(道具id)</template>-->
<!--          <template v-if="row.type===6">道具存款券(道具id)</template>-->
<!--          <template v-if="row.type===7">VIP下注返利(VIP特权奖励记录id)</template>-->
<!--          <template v-if="row.type===8">VIP黄金俱乐部(VIP特权奖励记录id)</template>-->
<!--          <template v-if="row.type===9">运营活动(运营活动id)</template>-->
<!--          <template v-if="row.type===10">每日优惠充值活动(存款订单号)</template>-->
<!--          <template v-if="row.type===11">注册赠送</template>-->
<!--          <template v-if="row.type===13">充值奖励</template>-->
<!--          <template v-if="row.type===14">人工入款</template>-->
<!--          <template v-if="row.type===15">每日挑战活动</template>-->
<!--          <template v-if="row.type===16">存钱罐活动</template>-->
<!--          <template v-if="row.type===17">新手任务活动</template>-->
<!--          <template v-if="row.type===18">每日签到活动</template>-->
<!--        </template>-->
<!--      </el-table-column>-->
      <el-table-column prop="money_type" label="赠金类型">
        <template v-slot="{row}">
          <template v-if="row.money_type===1">现金</template>
          <template v-if="row.money_type===2">奖金</template>
        </template>
      </el-table-column>
      <el-table-column prop="coins" label="赠送金额"></el-table-column>
<!--      <el-table-column prop="wallet_coins" label="真金余额"></el-table-column>-->
<!--      <el-table-column prop="bonus" label="赏金变化"></el-table-column>-->
<!--      <el-table-column prop="wallet_bonus" label="赏金余额"></el-table-column>-->
<!--      <el-table-column prop="" label="记录员"></el-table-column>-->
    </el-table>

    <el-pagination
        background
        :page-size="20"
        layout="prev, pager, next, jumper"
        :total="total"
        @current-change="pageChangeHandle"
        v-if="tableData.length>0"
    />
  </div>


</template>

<script>
export default {
  data() {
    const defaultItem = {};
    Object.keys(defaultItem).forEach((key) => (defaultItem[key] = ""));
    return {
      userid: this.$route.params.userid,
      tableData: [],
      item: {...defaultItem},
      queryParams: {},
      typeArr: [
        {label:'首充活动',value:1},
        {label:'每日存款任务',value:24},
        {label:'每日累计有效投注',value:25},
        {label:'每日单笔有效投注',value:26},
        {label:'首次存款任务',value:27},
        {label:'兑换码',value:23},
        {label:'存钱罐活动',value:16},
        {label:'每日签到活动',value:18},
        {label:'会员周返奖励',value:1321},
        {label:'会员月返奖励',value:1322},
        {label:'会员升级奖励',value:1323},
        {label:'红包雨',value:67},
        {label:'新手任务活动',value:17},
        {label:'道具现金券',value:3},
        {label:'道具存款券',value:6},
        {label:'运营活动',value:9},
        {label:'每日活动',value:10},
        {label:'注册赠送',value:11},
        {label:'充值奖励',value:13},
        {label:'人工入款',value:14},
        {label:'奖金转现任务',value:2},
        {label:'奖金提现任务',value:22},
        {label:'每日挑战活动',value:15},
        {label:'VIP下注返利',value:7},
        {label:'VIP黄金俱乐部',value:8},
        {label:'道具免费游戏',value:4},
        {label:'道具增益道具',value:5},
      ],
      betInfos: [
        {label: '获赠现金:', value: 0},
        {label: '获赠奖金:', value: 0},
      ],
      status: 1,
      total: 0,
      pages: 0,
      page: 0,
      orderDiffTime: '',
      dialogVisible: false,
    };
  },
  watch: {
    status() {
      this.queryData()
    }
  },
  methods: {
    resetHandle() {
      Object.keys(this.queryParams).forEach(
          (key) => (this.queryParams[key] = "")
      );
      // this.queryParams.time = [new Date(), new Date()],
      this.queryData();
    },
    pageChangeHandle(num) {
      this.queryData(num - 1);
    },
    queryData(index = 0) {
      this.finance();
      const params = this.$tools.removeEmptyProperties(this.queryParams);
      if (params.time) {
        params.start_time = this.$tools.dateFormat(params['time'][0], 2);
        params.end_time = this.$tools.dateFormat(params['time'][1], 2);
      }
      params.userid = this.userid
        let data = {
          cmd: "gift_record",
          action: "query_gift_list",
          data: {
            paramVO: params,
            page: index,
          },
        };
        this.$http
            .post("gift_record", data)
            .then((response) => {
              console.log("response.data", response.data);
              this.tableData = response.data.gift_logs;
              this.tableData.forEach((item) => {
                for(let i of this.typeArr){
                  if(i.value==item.type){
                    item.type_str = i.label;
                    break;
                  }
                }
              });
            })
            .catch((error) => {
              console.log("error", error);
            });
    },
    finance() {
      const params = this.$tools.removeEmptyProperties(this.queryParams);
      if (params.time) {
        params.min_query_time = this.$tools.dateFormat(params['time'][0], 2);
        params.max_query_time = this.$tools.dateFormat(params['time'][1], 2);
      }
      params.userid = this.userid
      const data = {
        cmd: "user_behavior",
        action: "query_gift_record",
        data: {
          paramVO: params,
        },
      };
      // this.btnSaveLoading = true;
      this.$http
          .post("user_behavior", data)
          .then((response) => {
            console.log("response.data", response.data);
            this.betInfos[0].value = response.data.coins;
            this.betInfos[1].value  = response.data.give_coins;
          })
          .catch((error) => {
            console.log("error", error);
          });
    },

  },
  mounted() {
    this.queryData();

  },
};
</script>

<style lang="scss" scoped>
.bet-info {
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  margin: 10px;
  display: flex;
  justify-content: space-between;
  //align-items: center;
  flex-direction: column;
  color: #0d1717;
  border: 1px solid #0d1717;
  font-size: 100%;
  line-height: 150%;
}
</style>
